import React from 'react'
import { Link } from 'gatsby'
import SEO from '../components/seo'
import Button from '../components/Button'
import './404.scss'

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <div className="page404">
      <br />
      <br />
      <br />
      <h2>Page not found</h2>
      <Link to="/">
        <Button color="accent2" style={{ marginTop: '40px' }}>
          Return home
        </Button>
      </Link>
    </div>
  </>
)

export default NotFoundPage
